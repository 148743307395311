:root {
  --main-color: #158D2F;
  --second-color: #EB5757;
  --text-color: #545454;
  --gray-color: #f5f5f5;
  --white-color: #FFF;
}

html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  font-family: Raleway;
  min-width: 1000px;
  height: 100%;
}

#root{
  display: flex;
  flex-flow: column;
  height: 100%;
}

a, a:visited {
  text-decoration: none;
  color: var(--text-color);
}

.divabsolute{
  position: absolute;
}

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway/static/Raleway-Regular.ttf') format('truetype');
}
