.home > img {
    height: 100vh;
    width: 100%;
    object-fit: cover;
}

.banner{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20em;
    position: absolute;
}

.banner > img {
    width: 20%;
    min-width: 250px;
}
.banner > p {
    margin: 10px;
    width: 50%;
    min-width: 300px;
    text-align: center;
    font-size: 2em;
    line-height: 1.5em;
    text-transform: uppercase;
    color: aliceblue;
    background-color: rgba(0, 0, 0, .3);
    padding: .5em;
}

.banner > a {
    margin: 10px;
    text-align: center;
    font-size: 1.5em;
    line-height: .1em;
    color: aliceblue;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, .4);
}

.banner > a:hover {
    background-color: rgba(0, 0, 0, .6);
    color: white;
}