.header, .header-white {
    display: flex;
    flex: 0 1 auto;
    padding-top: 2em;
    padding-bottom: 1em;
    align-items: center;
    width: 100%;
    min-width: 1000px;
}

.header-white {
    background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,1));
}

.logo, .logo-white {
    margin-left: 1em;
    flex: 1.5;
    font-family: Georgia;
    font-size: 2em;
}

.logo > a , .logo-white > a {
    color: var(--main-color);
}

.logo-white > a {
    color: #FFF;
}

.logo > a > span, .logo-white > a > span {
    cursor: pointer;
    color: var(--second-color);
}

.menu, .menu-white {
    display: flex;
    justify-content: space-evenly;
    flex: 4;
    font-size: 1.2em;
    text-transform: uppercase;
}

.menu-white > a{
    color: #FFF;
}

.menu > a > label, .menu-white > a > label {
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}
.menu > a > label:hover{
    font-weight: bold;
}

.menu > a > label.selected , .menu-white  > a > label.selected-white{
    border-bottom: 4px solid var(--main-color);
    font-weight: bold;
}

.menu-white  > a > label.selected-white{
    border-bottom: 4px solid #FFF;
    font-weight: bold;
}

.menu-white  > a > label:hover{
    font-weight: bold;
}


.lang > div {
    display: flex;
}

.lang {
    display: flex;
    justify-content: flex-end;
    flex: .5;
    align-items: center;
}

.lang > img {
    width: 30px;
    cursor: pointer;
    object-fit: cover;
    height: 80%;
}

.flag:hover {
    box-shadow: 2px 2px 2px #888888;
}

.lang > ul {
    padding: 0;
    position: fixed;
    top: 0;
}

.flags-button{
    border: none;
    color: white;
}