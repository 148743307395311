.footer {
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    background-color: var(--main-color);
    align-items: center;
    padding: 1em;
    color: white;
}

.footer > label {
    padding: 10px;
}

.footer > label > a {
    color: white;
}

.footer > label > span {
    padding: 10px;
    font-size: 20px;
    font-weight: bolder;
}

.footer > div {
    display: flex;
    padding: 10px;
    justify-content: center;
}

.footer > div > a {
    display: flex;
    padding: 5px;
    justify-content: center;
}
.footer > div > a > img {
    width: 30px;
}