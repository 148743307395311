.contact-message {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-left: 2em;
    padding-right: 2em;
}
.contact-message > p {
    font-size: 1.3em;
    margin: 15px 0 10px 0;
}
.contact-message-label {
    font-size: 1.5em;
    font-weight: bolder;
    margin-top: 1em;
}

.contact-message-label > span {
    color: var(--main-color);
}
.contact {
    display: flex;
    flex-direction: row;
    /* margin-top: 2em; */
    align-items: center;
}
.contact > div {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
}
.phone {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.phone > label {
    margin-left: 1em;
}

.contact-message-map {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.contact-message-map > div{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 2em;
}

.contact-message-map > div > iframe{
    margin-top: 1em;
}

.phone-last {
    margin-bottom: 20px;
}