.reseller {
    display: flex;
    flex-direction: column;
    padding: 0px 50px ;
    margin-bottom: 2em;
}

.reseller-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reseller-card {
    display: flex;
    flex-direction: column;
    background-color: var(--gray-color);
    margin-right: 5%;
    margin-bottom: 2em;
    width: 20%;
    border-radius: 7px;
}

.reseller-card-header {
    background-color: var(--main-color);
    color: var(--white-color);
    font-weight: bolder;
    border-radius: 7px 7px 0px 0px;
}

.reseller-card > div {
    padding: 1em;
}

.reseller-card-body {
    display: flex;
    flex-direction: column;
}

.reseller-card-body > div > div >label, .reseller-card-body > div >label {
    margin-bottom: .5em;
    margin-left: 1em;
}

.reseller-card-body > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.reseller-card-body > div > div {
    display: flex;
    flex-direction: column;
}

.work-with-us{
    align-self: center;
    margin-top: 2em;
    width: 50%;
}

.work-with-us > div{
    display: flex;
    flex-direction: row;
}

.work-with-us > label{
    font-size: 1.6em;
    font-weight: bolder;
}

.work-with-us > label > span {
    color: var(--main-color);
}

.work-with-us > div > p{
    font-size: 1.2em;
    text-align: justify;
}

.work-with-us > div > p > span {
    font-weight: bold;
}

.work-with-us > div > p > div {
    margin-top: 10px;
    font-weight: bold;
    font-style: italic;
}

.work-with-us > div > p > a {
    color: var(--main-color);
    font-weight: bold;
    text-decoration: underline;
}